export default class EventUtility {
	event = null;

	constructor(event) {
		this.event = event;
	}

	isTouchEvent() {
		return this.event.touches && this.event.touches.length > 0;
	}

	getClientY() {
		return this.isTouchEvent() ? this.event.touches[0].clientY : this.event.clientY;
	}

	getClientX() {
		return this.isTouchEvent() ? this.event.touches[0].clientX : this.event.clientX;
	}
}
