import React from 'react';
import { ModuleType } from '@vodafoneis/sjonvarpskjarni-js-lib';
import useContentGridRenderer from '../../hooks/useContentGridRenderer';
import { MediaComponentType } from '../../models/MediaComponentType';
import MediaCarousel from '../../components/MediaCarousel';

const { EVENT } = MediaComponentType;
type SlugEventsSceneProps = {
	id: number;
	events: Event[];
	title: string;
	moduleType?: ModuleType;
};
export const SlugEventCarousel: React.FC<SlugEventsSceneProps> = ({ id, title, events, moduleType }) => {
	const eventModuleType = moduleType || ModuleType.M3; // default to M3 if its not supplied by the backend
	const renderItem = useContentGridRenderer({ moduleType: eventModuleType, mediaComponentType: EVENT });

	if (!events) return null;

	return <MediaCarousel id={`sport-world-events-${id}`} title={title} items={events} renderItem={renderItem} />;
};
