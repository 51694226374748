import React from 'react';
import { useTranslation } from 'react-i18next';
import { FetchPolicy } from '@apollo/client';
import { ModuleType, useWatchHistory } from '@vodafoneis/sjonvarpskjarni-js-lib';
import MediaCarousel from './MediaCarousel';
import useContentGridRenderer from '../hooks/useContentGridRenderer';
import { CONTENT_LIMIT } from '../config/constants';
import { MediaComponentType } from '../models/MediaComponentType';

const { HISTORY } = MediaComponentType;
const { M4 } = ModuleType;

const queryOptions = {
	fetchPolicy: 'cache-and-network' as FetchPolicy,
};

export default function WatchHistoryCarousel() {
	const { t } = useTranslation();

	const renderItem = useContentGridRenderer({ moduleType: M4, mediaComponentType: HISTORY });

	const { watchHistory, fetchMoreWatchHistory } = useWatchHistory(CONTENT_LIMIT, queryOptions);

	if (watchHistory?.length > 0) {
		return (
			<MediaCarousel
				id={'carousel-watch-history'}
				title={t('MyContent.WatchHistory')}
				items={watchHistory}
				detailsUrl={'/history'}
				renderItem={renderItem}
				fetchMoreItems={fetchMoreWatchHistory}
			/>
		);
	}

	return null;
}
