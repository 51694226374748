import React from 'react';
import { Category, useCategory } from '@vodafoneis/sjonvarpskjarni-js-lib';
import MediaCarousel from './MediaCarousel';
import useContentGridRenderer from '../hooks/useContentGridRenderer';
import { CONTENT_LIMIT } from '../config/constants';

type CategoryCarouselProps = {
	id: string;
	title?: string;
	category: Category;
};

const CategoryCarousel: React.FC<CategoryCarouselProps> = ({ id, title, category }) => {
	const { fetchMoreContent } = useCategory(category.id, CONTENT_LIMIT);
	const { moduleType, moduleTemplate } = category;

	const renderItem = useContentGridRenderer({ moduleType, moduleTemplate });

	if (!category.content) return null;

	return <MediaCarousel id={id} title={title || category.title} items={category.content} renderItem={renderItem} detailsUrl={`/category/${category.id}/grid`} fetchMoreItems={fetchMoreContent} />;
};

export default CategoryCarousel;
