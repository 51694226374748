import React, { useMemo, useContext, useCallback, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import Link from 'next/link';
import { FetchMoreCallback } from '@vodafoneis/sjonvarpskjarni-js-lib';
import Carousel from './Carousel';
import { CarouselContext, CarouselContextProvider } from '../contexts/CarouselContext';
import CAROUSEL_SCROLL_POSITION_QUERY from '../graphql/CarouselScrollPosition.graphql';
import { ListTitle } from './ListTitle';
import { breakpoints } from '../styles/breakpoints';
import chevronLeft from '../../public/images/chevron-left.png';
import chevronRight from '../../public/images/chevron-right.png';
import { Icon } from './IconComponent/IconComponent';
import { media } from '../ui/utils/media';

const MaxWidthContainer = styled.div`
	width: 100%;
	max-width: 100vw;
	${media.desktopUp} {
		max-width: 80vw;
	}
`;

const CarouselContainer = styled.div`
	margin-bottom: 15px;
	position: relative;

	@media ${breakpoints.md} {
		margin-bottom: 30px;
	}
`;

type CarouselActionsContainerProps = {
	withTitle?: boolean;
};

const CarouselActionsContainer = styled.div<CarouselActionsContainerProps>`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin-top: ${(props) => (props.withTitle ? '56px' : 0)};
	margin-bottom: 10px;
	pointer-events: none;
	display: none;

	@media (hover) {
		display: block;
		opacity: 0.5;
		transition: opacity 0.2s ease-in;

		&:hover {
			opacity: 0.85;
		}
	}
`;

type CarouselActionProps = {
	visible?: boolean;
};

const CarouselAction = styled.button<CarouselActionProps>`
	position: absolute;
	min-width: 40px;
	width: 3%;
	height: 100%;
	cursor: pointer;
	background-color: #333;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 14px 28px;
	z-index: 1;
	opacity: ${(props) => (props.visible ? 1 : 0)};
	pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;

const CarouselActionPrevious = styled(CarouselAction)`
	left: 0;
	margin-left: -3%;
	${media.tabletLandscapeUp} {
		margin-left: -${({ theme }) => theme.screenLayoutGutters.tabletLandscape.left}px;
	}

	${media.desktopUp} {
		margin-left: -${({ theme }) => theme.screenLayoutGutters.desktop.left}px;
	}

	${media.desktopLargeUp} {
		margin-left: -${({ theme }) => theme.screenLayoutGutters.desktopLarge.left}px;
	}

	${media.desktopXLargeUp} {
		margin-left: -${({ theme }) => theme.screenLayoutGutters.desktopXLarge.left}vw;
	}
	background-image: url(${chevronLeft.src});
`;

const CarouselActionNext = styled(CarouselAction)`
	right: 0;
	margin-right: -3%;
	${media.tabletLandscapeUp} {
		margin-right: -${({ theme }) => theme.screenLayoutGutters.tabletLandscape.right}px;
	}

	${media.desktopUp} {
		margin-right: -${({ theme }) => theme.screenLayoutGutters.desktop.right}px;
	}

	${media.desktopLargeUp} {
		margin-right: -${({ theme }) => theme.screenLayoutGutters.desktopLarge.right}px;
	}

	${media.desktopXLargeUp} {
		margin-right: -${({ theme }) => theme.screenLayoutGutters.desktopXLarge.right}vw;
	}
	background-image: url(${chevronRight.src});
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	min-width: 0;
	margin-bottom: 5px;
	${media.tabletPortraitUp} {
		margin-bottom: 10px;
	}
`;

const SeeAllLink = styled.a`
	cursor: pointer;
	display: flex;
	align-items: center;
	&:hover,
	:active,
	:hover {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.white};
	}
`;

type MediaCarouselProps = {
	id: string;
	title?: string;
	detailsUrl?: string;
	items?: any[];
	renderItem: (item: any, clickEnabled: boolean) => any;
	fetchMoreItems?: FetchMoreCallback;
};

const MediaCarouselContent: React.FC<MediaCarouselProps> = ({ id, title, items, fetchMoreItems, renderItem }) => {
	const client = useApolloClient();
	const { scrollPosition, setScrollPosition, previousPage, nextPage, clickEnabled, leftArrowVisible, rightArrowVisible } = useContext(CarouselContext);

	useEffectOnce(() => {
		try {
			const {
				carouselScrollPosition: { scrollPosition: initialScrollPosition },
			} = client.readQuery({
				query: CAROUSEL_SCROLL_POSITION_QUERY,
				variables: { id },
			});
			if (initialScrollPosition < 0) {
				setScrollPosition(initialScrollPosition);
			}
		} catch (exception) {}
	});

	useEffect(() => {
		client.writeQuery({
			query: CAROUSEL_SCROLL_POSITION_QUERY,
			data: {
				carouselScrollPosition: {
					id,
					scrollPosition,
				},
			},
			variables: { id },
		});
	}, [client, id, scrollPosition]);

	const itemComponents = useMemo(() => {
		if (!items || items.length === 0) return null;
		return items.map((movie) => renderItem(movie, clickEnabled));
	}, [clickEnabled, items, renderItem]);

	const onPreviousClick = useCallback(
		(e) => {
			e.preventDefault();
			previousPage();
		},
		[previousPage]
	);

	const onNextClick = useCallback(
		(e) => {
			e.preventDefault();
			nextPage();
		},
		[nextPage]
	);

	return (
		<>
			<MaxWidthContainer>
				<Carousel fetchMoreItems={fetchMoreItems}>
					<div className="row">{itemComponents}</div>
				</Carousel>
			</MaxWidthContainer>
			<CarouselActionsContainer withTitle={!!title}>
				<CarouselActionPrevious visible={leftArrowVisible} onClick={onPreviousClick} />
				<CarouselActionNext visible={rightArrowVisible} onClick={onNextClick} />
			</CarouselActionsContainer>
		</>
	);
};

const MediaCarousel: React.FC<MediaCarouselProps> = ({ id, title, detailsUrl, items, renderItem, fetchMoreItems }) => {
	const titleComponent = useMemo(() => {
		if (!title) return null;
		if (!detailsUrl) {
			return (
				<TitleContainer>
					<ListTitle>{title}</ListTitle>
				</TitleContainer>
			);
		}
		return (
			<TitleContainer>
				<Link href={detailsUrl} passHref>
					<SeeAllLink>
						<ListTitle>{title}</ListTitle>
						<Icon type="ARROW_RIGHT" />
					</SeeAllLink>
				</Link>
			</TitleContainer>
		);
	}, [title, detailsUrl]);

	if (!items || items.length === 0) return null;

	return (
		<CarouselContainer>
			{titleComponent}
			<CarouselContextProvider>
				<MediaCarouselContent id={id} title={title} items={items} fetchMoreItems={fetchMoreItems} renderItem={renderItem} />
			</CarouselContextProvider>
		</CarouselContainer>
	);
};

export default MediaCarousel;
