import React from 'react';
import styled from 'styled-components';
import { Ad } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { AD_TYPE_SPORT_WORLD } from '../../config/constants';
import { SlugEventCarousel } from './SlugEventCarousel';

const FrontpageCategoriesContainer = styled.div`
	margin: 40px 0;
`;

type FrontpageCategoriesProps = {
	categories?: Ad[];
};

export const SlugEventScene: React.FC<FrontpageCategoriesProps> = ({ categories }) => {
	if (!categories || categories.filter((ad) => ad.category).length === 0) return null;
	return (
		<>
			<FrontpageCategoriesContainer>
				{categories.filter((c) => c.type === AD_TYPE_SPORT_WORLD && c?.events != null).map((ad) => (
					<SlugEventCarousel key={ad?.id} id={ad?.id} events={ad?.events} title={ad?.title} moduleType={ad?.category?.moduleType} />
				))}
			</FrontpageCategoriesContainer>
		</>
	);
};
