import React, { useRef, useEffect, useContext, useCallback, useMemo } from 'react';
import { usePrevious } from 'react-use';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import { FetchMoreCallback } from '@vodafoneis/sjonvarpskjarni-js-lib';
import EventUtility from '../utils/EventUtility';
import { CarouselContext } from '../contexts/CarouselContext';

const throttledScroll = throttle((cb) => cb(), 1000, {
	leading: true,
	trailing: false,
});

const CarouselContainer = styled.div`
	transition: transform 0.4s ease-in-out;

	&::-webkit-scrollbar {
		display: none;
	}

	> .row {
		flex-wrap: nowrap;
	}
`;

type CarouselProps = {
	fetchMoreItems?: FetchMoreCallback;
	children: React.ReactElement;
};

const Carousel: React.FC<CarouselProps> = ({ children, fetchMoreItems }) => {
	const { setContainerWidth, maxScrollPosition, setMaxScrollPosition, scrollPosition, previousPage, nextPage } = useContext(CarouselContext);

	const previousScrollPosition = usePrevious(scrollPosition);

	const carouselElement = useRef(null);
	const touchStartPosition = useRef(0);

	const onResize = useMemo(() => {
		return throttle(() => {
			const element = carouselElement.current;

			if (element) {
				const newContainerWidth = element.clientWidth + 10;
				setContainerWidth(newContainerWidth);
				setMaxScrollPosition((element.scrollWidth - newContainerWidth) * -1);
			}
		}, 200);
	}, [setContainerWidth, setMaxScrollPosition]);

	useEffect(() => onResize(), [children, onResize]);

	useEffect(() => {
		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
	}, [onResize]);

	useEffect(() => {
		(async () => {
			if (fetchMoreItems && scrollPosition !== previousScrollPosition && scrollPosition < maxScrollPosition * 0.7) {
				await fetchMoreItems();
				onResize();
			}
		})();
	}, [fetchMoreItems, maxScrollPosition, onResize, previousScrollPosition, scrollPosition]);

	const onTouchStart = useCallback((event) => {
		touchStartPosition.current = new EventUtility(event).getClientX();
	}, []);

	const onTouchMove = useCallback(
		(event) => {
			const delta = touchStartPosition.current - new EventUtility(event).getClientX();
			const threshold = 30;

			if (delta > threshold) {
				throttledScroll(nextPage);
			} else if (delta < threshold * -1) {
				throttledScroll(previousPage);
			}
		},
		[nextPage, previousPage]
	);

	const onWheel = useCallback(
		({ nativeEvent }) => {
			document.body.classList.add('disable-overscroll');

			const { deltaX, deltaY } = nativeEvent;

			if (deltaX !== 0 && deltaY === 0) {
				if (deltaX > 0) {
					throttledScroll(nextPage);
				} else if (deltaX < 0) {
					throttledScroll(previousPage);
				}
			}
		},
		[nextPage, previousPage]
	);

	const onMouseLeave = useCallback(() => document.body.classList.remove('disable-overscroll'), []);

	return (
		<CarouselContainer
			ref={carouselElement}
			style={{ transform: `translate3d(${scrollPosition}px, 0, 0)` }}
			onTouchStart={onTouchStart}
			onTouchMove={onTouchMove}
			onWheel={onWheel}
			onMouseLeave={onMouseLeave}
		>
			{children}
		</CarouselContainer>
	);
};

export default Carousel;
