import React, { createContext, useState, useCallback, useEffect } from 'react';

type CarouselContextProps = {
	containerWidth: number;
	setContainerWidth: (containerWidth: number) => void;
	maxScrollPosition: number;
	setMaxScrollPosition: (maxScrollPosition: number) => void;
	scrollPosition: number;
	setScrollPosition: (scrollPosition: number) => void;
	clickEnabled: boolean;
	setClickEnabled: (clickEnabled: boolean) => void;
	leftArrowVisible: boolean;
	setLeftArrowVisible: (leftArrowVisible: boolean) => void;
	rightArrowVisible: boolean;
	setRightArrowVisible: (leftArrowVisible: boolean) => void;
	previousPage: () => void;
	nextPage: () => void;
	scroll: (newPosition: number) => void;
};

export const CarouselContext = createContext<Partial<CarouselContextProps>>({});

export function getScrollPage(scrollPosition, containerWidth) {
	return Math.round(scrollPosition / containerWidth);
}

export function getScrollPosition(scrollPosition, maxScrollPosition) {
	return Math.max(Math.min(scrollPosition, 0), maxScrollPosition);
}

export const CarouselContextProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
	const [containerWidth, setContainerWidth] = useState(0);
	const [maxScrollPosition, setMaxScrollPosition] = useState(0);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [clickEnabled, setClickEnabled] = useState(true);
	const [leftArrowVisible, setLeftArrowVisible] = useState(false);
	const [rightArrowVisible, setRightArrowVisible] = useState(false);

	const previousPage = useCallback(() => {
		const page = getScrollPage(scrollPosition + containerWidth, containerWidth);
		setScrollPosition(getScrollPosition(page * containerWidth, maxScrollPosition));
	}, [containerWidth, scrollPosition, maxScrollPosition]);

	const nextPage = useCallback(() => {
		const page = getScrollPage(scrollPosition - containerWidth, containerWidth);
		setScrollPosition(getScrollPosition(page * containerWidth, maxScrollPosition));
	}, [containerWidth, scrollPosition, maxScrollPosition]);

	const scroll = useCallback(
		(newPosition) => {
			setScrollPosition(getScrollPosition(newPosition, maxScrollPosition));
		},
		[maxScrollPosition]
	);

	useEffect(() => {
		if (scrollPosition < 0) {
			setLeftArrowVisible(true);
		} else {
			setLeftArrowVisible(false);
		}

		if (scrollPosition > maxScrollPosition) {
			setRightArrowVisible(true);
		} else {
			setRightArrowVisible(false);
		}
	}, [maxScrollPosition, scrollPosition]);

	return (
		<CarouselContext.Provider
			value={{
				containerWidth,
				setContainerWidth,
				maxScrollPosition,
				setMaxScrollPosition,
				scrollPosition,
				setScrollPosition,
				clickEnabled,
				setClickEnabled,
				leftArrowVisible,
				setLeftArrowVisible,
				rightArrowVisible,
				setRightArrowVisible,
				previousPage,
				nextPage,
				scroll,
			}}
		>
			{children}
		</CarouselContext.Provider>
	);
};
