import React from 'react';
import styled from 'styled-components';
import { Ad, FetchMoreCallback, useInfiniteScroll } from '@vodafoneis/sjonvarpskjarni-js-lib';
import CategoryCarousel from '../../components/CategoryCarousel';
import { AD_TYPE_SPORT_WORLD } from '../../config/constants';

const FrontpageCategoriesContainer = styled.div`
	margin: 40px 0;
`;

type FrontpageCategoriesProps = {
	categories?: Ad[];
	fetchMore: FetchMoreCallback
};

export const FrontpageCategories: React.FC<FrontpageCategoriesProps> = ({ categories, fetchMore }) => {
	useInfiniteScroll(fetchMore);
	if (!categories || categories.filter((ad) => ad.category).length === 0) return null;
	return (
		<>
			<FrontpageCategoriesContainer>
				{categories.filter((c) => c.type !== AD_TYPE_SPORT_WORLD && c.category != null).map((ad) => (
					<CategoryCarousel key={ad.category.id} id={`carousel-frontpage-${ad.category.id}`} title={ad.title} category={ad.category} />
				))}
			</FrontpageCategoriesContainer>
		</>
	);
};
